<template>
  <div class="reports">
    <ReportPlayerWinloseComponent
      :ref-key-text="refKeyText"
      :title="title"
      :url-path="urlPath"
      :route-name="routeName"
      :is-simple="false"
      :is-search-username="true"
    />
  </div>
</template>

<script>
import ReportPlayerWinloseComponent from '@/components/ReportPlayerWinloseComponent.vue'

export default {
  components: {
    ReportPlayerWinloseComponent,
  },
  data() {
    return {
      refKeyText: 'Username',
      title: 'W/L Player (Detail)',
      urlPath: '/reports/v2/player_winlose_detail',
      routeName: 'reports-player-winlose-detail',
    }
  },
}
</script>

<style>

</style>
